// import React, { useEffect, useState } from "react";
// import img1 from "../images/sliderMain.png";
// // import img2 from "../images/slider1.png";
// import img2 from "../images/slider2.png";
// import img3 from "../images/slider3.png";
// import img4 from "../images/slider4.png";

// import { Link } from "react-router-dom";

// const data = [
//   {
//     id: 1,
//     title: "",
//     image: img1,
//   },
//   {
//     id: 2,
//     title: "",
//     image: img2,
//   },
//   {
//     id: 3,
//     title: "",
//     image: img3,
//   },
//   {
//     id: 4,
//     title: "",
//     image: img4,
//   },
// ];

// // const Slider = () => {
// //   const [currentSlide, setCurrentSlide] = useState(0);

// //   useEffect(() => {
// //     const interval = setInterval(() => {
// //       setCurrentSlide((prev) => (prev === data.length - 1 ? 0 : prev + 1));
// //     }, 5000); // Change every 7 seconds

// //     return () => clearInterval(interval);
// //   }, []);

// //   return (
// //     <div className="flex h-[45vh] md:h-[75vh] bg-fuchsia-50 relative overflow-hidden">
// //       {/* IMAGE AND TEXT CONTAINER */}
// //       {/* <div className="w-full absolute h-[45vh] md:h-[75vh]"> */}
// //       <div className="w-full absolute h-[45vh] md:h-[60vh] lg:h-[75vh]">
// //         {data.map((item, index) => (
// //           <div
// //             key={index}
// //             className={`absolute inset-0 flex flex-col justify-center items-center transition-opacity duration-[3000ms] ${
// //               index === currentSlide ? "opacity-100" : "opacity-0"
// //             }`}
// //           >
// //             <div className="w-full h-full flex items-center justify-center">
// //               {/* <img
// //                 src={item.image}
// //                 alt=""
// //                 loading="lazy"
// //                 className="w-full h-full object-cover transition-opacity duration-[2000ms]"
// //               /> */}
// //               <img
// //                 src={item.image}
// //                 alt=""
// //                 loading="lazy"
// //                 className="w-full h-full object-cover md:object-contain lg:object-cover transition-opacity duration-[2000ms]"
// //               />
// //             </div>
// //             <div className="absolute top-[40%] flex flex-col justify-center items-center">
// //               <h1 className="text-3xl text-center uppercase p-4 md:p-10 md:text-6xl xl:text-8xl text-white transition-opacity duration-[3000ms]">
// //                 {item.title}
// //               </h1>
// //             </div>
// //           </div>
// //         ))}
// //       </div>
// //     </div>
// //   );
// // };
// const Slider = () => {
//   const [currentSlide, setCurrentSlide] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentSlide((prev) => (prev === data.length - 1 ? 0 : prev + 1));
//     }, 5000);

//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <div className="relative w-full h-[50vh] sm:h-[60vh] md:h-[75vh] overflow-hidden">
//       {data.map((item, index) => (
//         <div
//           key={index}
//           className={`absolute inset-0 transition-opacity duration-1000 ${
//             index === currentSlide ? "opacity-100" : "opacity-0"
//           }`}
//         >
//           <img
//             src={item.image}
//             alt="Slider"
//             className="w-full h-full object-cover"
//           />
//           <div className="absolute inset-0 flex justify-center items-center text-white">
//             <h1 className="text-2xl md:text-4xl lg:text-6xl">{item.title}</h1>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Slider;

// import React, { useEffect, useState } from "react";
// import img1 from "../images/sliderMain.png";
// import img2 from "../images/slider2.png";
// import img3 from "../images/slider3.png";
// import img4 from "../images/slider4.png";

// const data = [
//   {
//     id: 1,
//     title: "", // Add titles if needed
//     image: img1,
//   },
//   {
//     id: 2,
//     title: "",
//     image: img2,
//   },
//   {
//     id: 3,
//     title: "",
//     image: img3,
//   },
//   {
//     id: 4,
//     title: "",
//     image: img4,
//   },
// ];

// const Slider = () => {
//   const [currentSlide, setCurrentSlide] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentSlide((prev) => (prev === data.length - 1 ? 0 : prev + 1));
//     }, 5000);

//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <div className="relative w-full h-[50vh] sm:h-[60vh] md:h-[75vh] overflow-hidden">
//       {data.map((item, index) => (
//         <div
//           key={index}
//           className={`absolute inset-0 transition-opacity duration-1000 ${
//             index === currentSlide ? "opacity-100" : "opacity-0"
//           }`}
//         >
//           <img
//             src={item.image}
//             alt={``}
//             className="w-full h-full object-cover"
//           />
//           <div className="absolute inset-0 flex justify-center items-center text-white">
//             <h1 className="text-2xl md:text-4xl lg:text-6xl text-center px-4">
//               {item.title}
//             </h1>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Slider;
// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";

// import img1 from "../images/sliderMain.png";
// // import img2 from "../images/slider1.png";
// import img2 from "../images/slider2.png";
// import img3 from "../images/slider3.png";
// import img4 from "../images/slider4.png";

// const Slider = () => {
//   const [currentSlide, setCurrentSlide] = useState(0);
//   const [images, setImages] = useState([]);

//   // Fetch images from your API
//   const data = [
//     {
//       id: 1,
//       title: "",
//       image: img1,
//     },
//     {
//       id: 2,
//       title: "",
//       image: img2,
//     },
//     {
//       id: 3,
//       title: "",
//       image: img3,
//     },
//     {
//       id: 4,
//       title: "",
//       image: img4,
//     },
//   ];

//   // Change slide every 10 seconds
//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentSlide((prev) => (prev === images.length - 1 ? 0 : prev + 1));
//     }, 10000);

//     return () => clearInterval(interval);
//   }, [images.length]);

//   return (
//     <div className="relative w-full h-[45vh] md:h-[75vh] bg-fuchsia-50 overflow-hidden">
//       {images.map((item, index) => (
//         <div
//           key={index}
//           className={`absolute inset-0 flex flex-col justify-center items-center transition-all duration-1000 ${
//             index === currentSlide
//               ? "opacity-100 transform-none"
//               : "opacity-0 transform translate-x-full"
//           }`}
//         >
//           {/* Image */}
//           <img
//             src={item.url}
//             alt={item.title}
//             className="w-full h-full object-cover transition-opacity duration-500"
//           />

//           {/* Title and Button */}
//           <div className="absolute top-[40%] flex flex-col justify-center items-center">
//             <h1 className="text-3xl md:text-4xl xl:text-6xl text-orange-400 uppercase text-center p-4 md:p-10 transition-opacity duration-500">
//               {item.title}
//             </h1>

//             <Link
//               to={`/product/${item._id}`}
//               className="bg-orange-500 text-white py-4 px-8 mt-4 transition-opacity duration-500 hover:bg-orange-600"
//             >
//               See More
//             </Link>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Slider;
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import img1 from "../images/sliderMain.png";
import img2 from "../images/slider2.png";
import img3 from "../images/slider3.png";
import img4 from "../images/slider4.png";

const data = [
  {
    id: 1,
    title: "", // Add titles if needed
    image: img1,
  },
  {
    id: 2,
    title: "",
    image: img2,
  },
  {
    id: 3,
    title: "",
    image: img3,
  },
  {
    id: 4,
    title: "",
    image: img4,
  },
];

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  // Change slide every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev === data.length - 1 ? 0 : prev + 1));
    }, 7000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative w-full h-[calc(100vh-80vh)] sm:h-[calc(100vh-75vh)] md:h-[75vh] bg-fuchsia-50 overflow-hidden">
      {data.map((item, index) => (
        <div
          key={item.id}
          className={`absolute inset-0 flex flex-col justify-center items-center transition-all duration-1000 ${
            index === currentSlide
              ? "opacity-100 transform-none"
              : "opacity-0 transform translate-x-full"
          }`}
        >
          {/* Image */}
          <img
            src={item.image}
            alt={item.title}
            className="w-full h-full object-cover transition-opacity duration-500"
          />

          {/* Title and Button */}
          <div className="absolute top-[40%] flex flex-col justify-center items-center">
            <h1 className="text-3xl md:text-4xl xl:text-6xl text-orange-400 uppercase text-center p-4 md:p-10 transition-opacity duration-500">
              {item.title}
            </h1>

            {/* <Link
              to={`/product/${item.id}`}
              className="bg-orange-500 text-white py-4 px-8 mt-4 transition-opacity duration-500 hover:bg-orange-600"
            >
              See More
            </Link> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Slider;
