import React from "react";
import img1 from "../images/zero_hunger.png";
import img2 from "../images/no_poverty.png";
import img3 from "../images/quality_education.png";
import ImageCard from "./ImageCard";

const data = [
  {
    title: "Zero Hunger",
    desc: "Dreamers Bangladesh is working to fight the nutrition crisis that a significant portion of the population face. With this aim several programs and projects have been undertaken. It is the organizations goal to achieve tangible results in thisendeavour.",
    img: img1,
  },
  {
    title: "No Poverty",
    desc: "Dreamers Bangladesh aims to empower the financially struggling families in the nation with the ability to gain a sustainable income that will continue to support them. Arranging for their livelihoods will ensure the impact reaches a large number of people.Dreamers Bangladesh is working to fight the nutrition crisis that a significant portion of the population face. With this aim several programs and projects have been undertaken. It is the organizations goal to achieve tangible results in thisendeavour.",
    img: img2,
  },
  {
    title: "Quality Education",
    desc: "Dreamers Bangladesh is an organization that works extensively in enhancing the access to education as well as creating avenues for skill-building among the youth of the nation. This outlook is based on the undeniable truth that the youth are the future.",
    img: img3,
  },
];

function WhoWeAre() {
  return (
    <div className="">
      <ImageCard data={data} />
    </div>
  );
}

export default WhoWeAre;
