import React, { useState } from "react";
import Modal from "./Modal";

const ImageCards = ({ data }) => {
  const [modalData, setModalData] = useState(null);

  const handleShowMore = (item) => {
    setModalData(item);
  };

  const closeModal = () => {
    setModalData(null);
  };

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-4">
        {data.map((item, index) => (
          <div key={index} className="border rounded-md p-4 bg-[#d3f1ed]">
            <img
              src={item.img}
              alt={item.title}
              className="w-full h-48 object-cover rounded-md"
            />
            <h4 className="font-semibold uppercase text-center text-xl mt-4">
              {item.title}
            </h4>
            <p className="mt-4 text-sm md:text-base">
              {item.desc.substring(0, 50)}...
            </p>
            <button
              className="mt-2 text-blue-500 hover:underline"
              onClick={() => handleShowMore(item)}
            >
              Show More
            </button>
          </div>
        ))}
      </div>

      {/* Modal */}
      {modalData && <Modal item={modalData} onClose={closeModal} />}
    </>
  );
};

export default ImageCards;
