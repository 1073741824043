import React from "react";
import img1 from "../../images/blog1.png";
import img2 from "../../images/blog2.png";
import img3 from "../../images/blog3.png";
import img4 from "../../images/blog4.png";
import Post from "../../Components/Post";

const data = [
  {
    title:
      "Dreamers Bangladesh: Leading Post-Flood Reconstruction in Southern Bangladesh with Sustainable Solutions",
    desc: "Dreamers Bangladesh has delivered 8,000 kilograms of relief to flood-affected areas in Cumilla, Noakhali, Feni, and Lakshmipur. Dreamers Bangladesh is now conducting post-flood reconstruction activities in the southern region. Responding to the calls of the most vulnerable people, our volunteers are working to fulfill the dreams of sustainable solutions like the installation of tube wells through Project: Safe Water and Project: স্বপ্নের স্বাবলম্বী. They are providing essential emergency services and standing by the affected people. To join us as a donor or donor organization in achieving our goals and objectives, please contact us.",
    images: {
      img1: img1,
      img2: img2,
      img3: img3,
      img4: img4,
    },
  },
  // {
  //   title: "Another Blog Title: Impacting Lives Through Innovation",
  //   desc: "This blog post focuses on how innovation and technology have transformed the lives of rural communities in Bangladesh, providing them with sustainable resources and better opportunities.",
  //   images: {
  //     img1: img2,
  //     img2: img3,
  //     img3: img4,
  //     img4: img1,
  //   },
  // },
];

const Blog = () => {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center space-y-8">
      {data.map((blogPost, index) => (
        <div key={index} className="w-full flex">
          {/* Left Section - 70% */}
          <div className="basis-[70%] p-4">
            <Post data={[blogPost]} />
          </div>

          {/* Right Section - 30% */}
          <div className="basis-[30%] p-4">
            {/* Post Title */}
            <h3 className="text-xl font-semibold mb-4">{blogPost.title}</h3>
            {/* First Image */}
            <img
              src={blogPost.images.img1}
              alt=""
              className="w-full h-auto object-cover rounded-lg"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Blog;
