import React from "react";

const Post = ({ data }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      {data.map((post, index) => (
        <div key={index} className="p-6">
          {/* Title */}
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            {post.title}
          </h2>

          {/* Description */}
          <p className="text-gray-600 text-lg mb-6">{post.desc}</p>

          {/* Images Grid */}
          <div className="grid grid-cols-2 gap-4">
            {Object.values(post.images).map((image, idx) => (
              <img
                key={idx}
                src={image}
                alt={``}
                className="w-full h-auto object-cover rounded-lg"
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Post;
