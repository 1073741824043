import React from "react";
import ImageCard from "./ImageCard";
import goal1 from "../images/goals1.png";
import goal2 from "../images/goals2.png";
import goal3 from "../images/goals3.png";
import goal4 from "../images/goals4.png";
import goal5 from "../images/goals5.png";
import goal6 from "../images/goals6.png";
import goal7 from "../images/goals7.png";
import goal8 from "../images/goals8.png";
import goal9 from "../images/goals9.png";
import goal10 from "../images/goals10.png";
import goal11 from "../images/goals11.png";

const data = [
  {
    title: "Promoting Social Inclusion and Intellectual Development",
    desc: "We are committed to fostering social inclusion by supporting talented individuals and improving their intellectual potential. Through various programs, we encourage the growth and development of promising youth, ensuring that no one is left behind in the pursuit of excellence.",
    img: goal1,
  },
  {
    title: "Empowering Grassroots Communities",
    desc: "Dreamers Bangladesh actively works for the socio-economic development of underprivileged and marginalised communities. We aim to improve the lives of those deprived of basic rights, focusing on building a strong and sustainable foundation for grassroots development.",
    img: goal2,
  },
  {
    title: "Raising Social Awareness and Training for Teenagers",
    desc: "By raising social awareness among teenagers, especially girls, we aim to build a self-reliant nation. Our diverse training programs equip young girls with the skills they need to thrive, empowering them to contribute to society's well-being.",
    img: goal3,
  },
  {
    title: "Promoting Universal Rights and Mental Health",
    desc: "Through initiatives focusing on education, health, culture, and sports, we work to promote universal rights for teenagers. These programs are designed to foster mental health, intellectual growth, and overall well-being, ensuring a holistic approach to youth development.",
    img: goal4,
  },
  {
    title: "Supporting Sustainable Development",
    desc: "We collaborate on various developmental activities aimed at improving the quality of life for the general public. Our goal is to create a sustainable and prosperous nation, where economic and social progress go hand-in-hand.",
    img: goal5,
  },
  {
    title: "Women Empowerment and Cyber Safety",
    desc: "Empowering women is at the core of our mission. We implement programs that focus on women’s cyber safety, preventing child marriage, promoting the health of adolescent girls, and ensuring safe motherhood. These initiatives aim to build a safer, healthier, and more empowered future for women.",
    img: goal6,
  },
  {
    title: "Humanitarian Relief and Disaster Response",
    desc: "Dreamers Bangladesh responds to natural disasters by providing humanitarian relief and promoting sustainable development. Our efforts include distributing aid, improving living conditions, and ensuring a dignified life for those in need.",
    img: goal7,
  },
  {
    title: "Promoting Social Forestry, Agriculture, and Economic Freedom",
    desc: "We are committed to environmental sustainability and economic empowerment. By promoting social forestry, agriculture, fisheries, and livestock development, we aim to create debt-free employment opportunities and foster economic freedom.",
    img: goal8,
  },
  {
    title: "Ensuring Worker Safety",
    desc: "Dreamers Bangladesh focuses on reviewing illegal establishments and addressing unsafe living conditions to protect workers and employees. We implement safety measures in factories, restaurants, and educational institutions to reduce risks and improve overall security.",
    img: goal9,
  },
  {
    title: "Collaborating with Law Enforcement for Public Safety",
    desc: "We work closely with government bodies and law enforcement agencies to ensure the proper enforcement of traffic laws, prevent corruption, and protect the public from fraud and misinformation. Our volunteers are dedicated to safeguarding the rights and safety of ordinary citizens.",
    img: goal10,
  },
  {
    title: "Cleanliness Drives and Climate Action",
    desc: "Environmental sustainability is one of our top priorities. We organise cleanliness drives, reduce plastic usage, and conduct climate action campaigns to raise awareness and combat climate change. Our goal is to build a cleaner, greener future.",
    img: goal11,
  },
];

const Goals = () => {
  return (
    <>
      <ImageCard data={data} />
    </>
  );
};

export default Goals;
