import React from "react";

import facebook from "../icons/facebook.png";
import insta from "../icons/instagram.png";
import youtube from "../icons/youtube.png";
import linkedin from "../icons/linkedin.png";
import logo2 from "../images/logo_2.png";
const Footer = () => {
  return (
    <footer className="bg-[#171443] text-white py-10">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
          {/* Left Section */}
          <div>
            <h2 className="font-semibold text-lg mb-4">About</h2>
            {/* <div className="object-cover">
              <img src={logo2} alt="" height={75} width={75} />
            </div> */}
            <p className="text-sm leading-relaxed">
              Dreamers Bangladesh is a non-profit apolitical volunteer youth
              organization that hopes to make a tangible difference in the lives
              of people. This aim guides the volunteers of Dreamers Bangladesh
              to dream of a nation that will stand above where it is and
              continue to thrive and grow well into the future.
            </p>
          </div>

          {/* Middle Section */}
          <div>
            <h2 className="font-semibold text-lg mb-4">Quick Links</h2>
            <ul className="space-y-2">
              <li>
                <a href="/about-us" className="hover:text-gray-300">
                  About
                </a>
              </li>
              <li>
                <a href="/contact" className="hover:text-gray-300">
                  Contact
                </a>
              </li>
              <li>
                <a href="/blog" className="hover:text-gray-300">
                  Blog
                </a>
              </li>
              <li>
                <a href="/career" className="hover:text-gray-300">
                  Career
                </a>
              </li>
              <li>
                <a href="/donate" className="hover:text-gray-300">
                  Donate
                </a>
              </li>
            </ul>
          </div>

          {/* Right Section */}
          <div>
            <h2 className="font-semibold text-lg mb-4">Contact Us</h2>
            <ul className="space-y-2 text-sm">
              <li>Location: Bashundhara R/A</li>
              <li>
                Email:{" "}
                <a
                  href="mailto:psycareorganization@gmail.com"
                  className="hover:text-gray-300"
                >
                  dreamersbangladesh@gmail.com
                </a>
              </li>
              <li>
                Phone:{" "}
                <a href="tel:+8801888579470" className="hover:text-gray-300">
                  +88 01777104344
                </a>
              </li>
            </ul>
            <div className="mt-6">
              <h2 className="font-semibold text-lg mb-2">Follow Us</h2>
              <div className="flex items-center justify-evenly space-x-4 bg-white w-[200px] h-[30px] rounded-[3px]">
                {/* Social Media Links with Sized Icons */}
                <a
                  href="https://www.facebook.com/dreamersbangladesh.Official"
                  className="hover:text-gray-300"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={facebook} alt="Facebook" className="w-6 h-6" />
                </a>
                <a
                  href="https://www.instagram.com/dreamersbangladesh_insta/"
                  className="hover:text-gray-300"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={insta} alt="Instagram" className="w-6 h-6" />
                </a>
                <a
                  href="https://www.youtube.com/@dreamersbangladesh"
                  className="hover:text-gray-300"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={youtube} alt="YouTube" className="w-6 h-6" />
                </a>
                <a
                  href="https://www.linkedin.com/company/dreamers-bangladesh/"
                  className="hover:text-gray-300"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedin} alt="LinkedIn" className="w-6 h-6" />
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Newsletter Subscription */}
        <div className="mt-10 border-t border-gray-700 pt-5">
          <h2 className="font-semibold text-lg mb-4">Get in touch</h2>
          <p className="text-sm mb-4"></p>
          <form className="flex">
            <input
              type="email"
              placeholder="yourmail@example.com"
              className="w-full md:w-1/2 p-2 rounded-l-md focus:outline-none text-gray-800"
            />
            <button
              type="submit"
              className="bg-[#32c6a6] text-white px-4 py-2 rounded-r-md hover:bg-[#2596be] transition duration-300"
            >
              Send
            </button>
          </form>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
