import React from "react";
import Slider from "../../Components/Slider";
import Partners from "../../Components/Partners";
import Impact from "../../Components/Impact";
import init from "../../images/Intiative.png";
// import CountUp from "react-countup";
import Goals from "../../Components/Goals";
import WhoWeAre from "../../Components/WhoWeAre";

// const Home = () => {
//   return (
//     <div className="">
//       <Slider />
//       <div className="w-full flex items-center justify-center flex-col">
//         <div className="w-[1100px] max-w-[1100px] mt-[22px] flex items-center justify-center flex-col">
//           <h1
//             id="problems-section"
//             className=" uppercase mb-[10px] text-[40px] font-semibold text-[#171443]"
//           >
//             Who We Are
//           </h1>
//           <h4 className="uppercase mb-[20px] text-[20px] font-semibold text-[#171443]">
//             Embrace every Opportunity | Encourage every Dream | Inspire every
//             soul
//           </h4>
//           <div className=" text-[#171443] text-[16px]">
//             <p className="mb-[10px] md:mb-[20px]">
//               Dreamers Bangladesh is a non-profit apolitical volunteer youth
//               organization that hopes to make a tangible difference in the lives
//               of people. Founded on July 6th, 2020.Dreamers Bangladesh has since
//               grown to become a leading organisation in its field.Dreamers
//               Bangladesh has over 40,000 members working throughout Bangladesh
//               to bring its mission to life. The organization has over 1400
//               active volunteers currently working throughout 21 districts. The
//               three core issues the organization tackles is raised from the
//               United Nations SDG goals, specifically:
//             </p>
//             <div className="sm:flex sm:flex-col md:flex md:flex-row gap-2">
//               <>
//                 {data.map((item, index) => (
//                   <BasicCard key={index} title={item.title} desc={item.desc} />
//                 ))}
//               </>
//             </div>
//           </div>
//         </div>
//         <div className="w-[1100px] max-w-[1100px] mt-[50px] flex items-center justify-center flex-col">
//           <h1
//             id="problems-section"
//             className=" uppercase mb-[20px] text-[40px] font-semibold text-[#171443]"
//           >
//             Our Initiatives Encompass
//           </h1>
//           <div className=" text-[#171443] text-[16px]">
//             <p className="">
// Dreamers Bangladesh has over the past five years since July 2020
// initiated many projects and programs that have had a large number
// of beneficiaries. The full range of the initiatives are diverse
// but they mostly fall under the umbrella of the three primary
// targets for the organization.
//             </p>
//           </div>
//           <div className="w-full h-full flex items-center justify-center my-[15px]">
//             {/* <img
//                 src={item.image}
//                 alt=""
//                 loading="lazy"
//                 className="w-full h-full object-cover transition-opacity duration-[2000ms]"
//               /> */}
//             <img
//               src={init}
//               alt=""
//               loading="lazy"
//               className="w-full h-full object-cover md:object-contain lg:object-cover "
//             />
//           </div>
//           <p className="text-[#171443] text-[16px]">
// We believe in the transformative power of education and have
// tirelessly worked to provide educational opportunities to
// marginalised communities across Bangladesh. Through educational
// content, mentorship programs, and educational workshops, we aim to
// equip the youth with the knowledge and skills necessary to thrive in
// the modern world.
//           </p>
//         </div>
// <div className="w-[1100px] max-w-[1100px] mt-[50px] flex items-center justify-center flex-col">
//   <h1
//     id="problems-section"
//     className=" uppercase mb-[20px] text-[40px] font-semibold text-[#171443]"
//   >
//     Our Goal
//   </h1>
//   <p className="text-[#171443] text-[16px]">
//     "To ignite the spirit of ambition and social responsibility among
//     the youth of Bangladesh, fostering a nationwide movement of dreamers
//     committed to positive social change."
//   </p>
// </div>
// <div className="w-[1100px] max-w-[1100px] mt-[50px] flex items-center justify-center flex-col mb-[20px]">
//   <h1
//     id="impact-section"
//     className=" uppercase mb-[20px] text-[40px] font-semibold text-[#171443]"
//   >
//     Our Impact
//   </h1>
//   <Impact />
// </div>
// <div className="w-[1100px] max-w-[1100px] mt-[50px] flex items-center justify-center flex-col mb-[20px]">
//   <h1
//     id="partners-section"
//     className=" uppercase mb-[20px] text-[40px] font-semibold text-[#171443]"
//   >
//     Our Partners
//   </h1>
//   <Partners />
// </div>
//       </div>
//     </div>
//   );
// };
//

const Home = () => {
  return (
    <div className="w-full">
      <Slider />

      {/* Who We Are Section */}
      <div className="w-full flex items-center justify-center flex-col px-4 md:px-0">
        <div className="w-full max-w-[1100px] mt-8 flex flex-col items-center justify-center">
          <h1 className="text-2xl md:text-4xl font-semibold text-[#171443] uppercase mb-4 text-center">
            Who We Are
          </h1>
          <h4 className="text-lg md:text-xl font-semibold text-[#171443] uppercase mb-6 text-center">
            Embrace every Opportunity | Encourage every Dream | Inspire every
            soul
          </h4>
          <div className="text-[#171443] text-sm md:text-base text-center md:text-left">
            <p className="mb-6">
              Dreamers Bangladesh is a non-profit apolitical volunteer youth
              organization that hopes to make a tangible difference in the lives
              of people. Founded on July 6th, 2020, Dreamers Bangladesh has
              since grown to become a leading organization in its field.
              Dreamers Bangladesh has over 40,000 members working throughout
              Bangladesh to bring its mission to life. The organization has over
              1400 active volunteers currently working throughout 21 districts.
              The three core issues the organization tackles are raised from the
              United Nations SDG goals, specifically:
            </p>
            <div className="flex flex-col md:flex-row gap-4">
              {/* {data.map((item, index) => (
                <BasicCard
                  key={index}
                  title={item.title}
                  desc={item.desc}
                  id={item.id}
                />
              ))} */}
              <WhoWeAre />
            </div>
          </div>
        </div>

        {/* Our Initiatives Section */}
        <div className="w-full max-w-[1100px] mt-12 flex flex-col items-center px-4">
          <h1 className="text-2xl md:text-4xl font-semibold text-[#171443] uppercase mb-8 text-center">
            Our Goals
          </h1>
          <img
            src={init}
            alt="Initiatives"
            className="w-full max-w-[1100px] h-auto object-cover my-8"
          />
          <p className="text-[#171443] text-sm md:text-base text-center md:text-left mb-4">
            Dreamers Bangladesh has over the past five years since July 2020
            initiated many projects and programs that have had a large number of
            beneficiaries. The full range of the initiatives are diverse, but
            they mostly fall under the umbrella of the three primary targets for
            the organization.
          </p>

          <p className="text-[#171443] text-sm md:text-base text-center md:text-left mb-4">
            We believe in the transformative power of education and have
            tirelessly worked to provide educational opportunities to
            marginalized communities across Bangladesh. Through educational
            content, mentorship programs, and educational workshops, we aim to
            equip the youth with the knowledge and skills necessary to thrive in
            the modern world.
          </p>
        </div>

        {/* Our Goal Section */}
        <div className="w-full max-w-[1100px] mt-[50px] flex items-center justify-center flex-col">
          <h1
            id="problems-section"
            className="text-2xl md:text-4xl font-semibold text-[#171443] uppercase mb-[20px] text-center"
          >
            What We Do
          </h1>
          {/* <p className="text-[#171443] text-sm md:text-base text-center">
            "To ignite the spirit of ambition and social responsibility among
            the youth of Bangladesh, fostering a nationwide movement of dreamers
            committed to positive social change."
          </p> */}
          <Goals />
        </div>

        {/* Our Impact Section */}
        <div className="w-full max-w-[1100px] mt-[50px] flex items-center justify-center flex-col mb-[20px]">
          <h1
            id="impact-section"
            className="text-2xl md:text-4xl font-semibold text-[#171443] uppercase mb-[20px] text-center"
          >
            Our Impact
          </h1>
          <Impact />
        </div>

        {/* Our Partners Section */}
        <div className="w-full max-w-[1100px] mt-[50px] flex items-center justify-center flex-col mb-[20px]">
          <h1
            id="partners-section"
            className="text-2xl md:text-4xl font-semibold text-[#171443] uppercase mb-[20px] text-center"
          >
            Our Partners
          </h1>
          <Partners />
        </div>
      </div>
    </div>
  );
};

export default Home;
