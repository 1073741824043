import React from "react";
import projects1 from "../../images/projects1.png";
const Projects = () => {
  return (
    <div className="">
      <div className="w-full h-full flex items-center justify-center">
        <img src={projects1} alt="" className="object-cover" />
      </div>
    </div>
  );
};

export default Projects;
