import React from "react";
import banner1 from "../../images/banner2.jpeg";
const ActivityHistory = () => {
  return (
    <div className="w-full flex items-center justify-center flex-col">
      <div className="w-full h-[350px]">
        <img src={banner1} alt="" className="object-cover  w-full h-[350px]" />
      </div>
      <div className="w-[1100px] max-w-[1100px] mt-[50px] flex items-center justify-center flex-col">
        <h1
          id="problems-section"
          className=" uppercase mb-[40px] text-[40px] font-semibold text-[#171443]"
        >
          Project Dreamers Happiness
        </h1>
        <p className="text-center text-[#171443] text-[16px]">
          "Project Dreamers Happiness" 2021 exemplified Dreamers Bangladesh's
          dedication to nurturing leadership skills and fostering social
          responsibility among the youth of Bangladesh. With a steadfast
          commitment to empowering future leaders, In a charitable endeavour,
          Team Dreamers Bangladesh distributed iftar meals to the
          underprivileged and street children in Mohammadpur, Dhaka, with 550
          people benefiting from this initiative. We also provided meals to 150
          people each in Kuril Bishwa road and Uttara. By extending support to
          the less fortunate in these areas, Team Dreamers Bangladesh not only
          provided essential sustenance but also instilled in the youth a sense
          of empathy, leadership, and social responsibility.
        </p>
      </div>
      <div className="w-[1100px] max-w-[1100px] mt-[50px] flex items-center justify-center flex-col">
        <h1
          id="problems-section"
          className=" uppercase mb-[40px] text-[40px] font-semibold text-[#171443]"
        >
          Manobotayx Dreamers Bangladesh
        </h1>
        <p className="text-center text-[#171443] text-[16px]">
          "Manobotay Dreamers Bangladesh" 2022 was a humanitarian effort carried
          out in Sitakunda, Chattogram, aimed at providing assistance to 150
          individuals who were hospitalised due to burns sustained in a fire
          incident. Each person received a week's worth of groceries, ensuring
          they had access to essential sustenance during their recovery period.
          This charitable endeavour exemplified Dreamers Bangladesh's commitment
          to alleviating the suffering of those in need and extending a helping
          hand to communities facing adversity.
        </p>
      </div>
      <div className="w-[1100px] max-w-[1100px] mt-[50px] flex items-center justify-center flex-col">
        <h1
          id="problems-section"
          className=" uppercase mb-[40px] text-[40px] font-semibold text-[#171443]"
        >
          2 takay kombol
        </h1>
        <p className="text-center text-[#171443] text-[16px]">
          "2 takay" 2023 was a compassionate initiative held in Kuril
          Bishwaroad, Dhaka, aimed at providing critically needed cold weather
          clothes and blankets to more than fifty families for the nominal
          amount of 2 taka each. The symbolic gesture of offering the items for
          a minimal price was intended to preserve the dignity of the
          recipients, allowing them to hold their heads up despite their
          unfortunate circumstances. Moreover, the primary objective was to
          alleviate the intense suffering experienced by these families during
          the harsh winter cold.
        </p>
      </div>
      <div className="w-[1100px] max-w-[1100px] mt-[50px] flex items-center justify-center flex-col">
        <h1
          id="problems-section"
          className=" uppercase mb-[40px] text-[40px] font-semibold text-[#171443]"
        >
          Unleash Your Inner Warrior
        </h1>
        <p className="text-center text-[#171443] text-[16px]">
          "Unleash Your Inner Warrior" 2023 was an empowering workshop held at
          Independent University Bangladesh, Dhaka, focused on equipping
          participants with realistic self-defence strategies to navigate any
          situation with confidence. The event aimed to raise awareness about
          self-defence while providing attendees with practical knowledge and
          skills to enhance their personal safety. Led by Bangladeshi Muay Thai
          Fighter Masjedul Reza Omeo, a champion with Gold Medals from the
          International Federation of Muaythai Associations (IFMA), participants
          learned crucial situational awareness, escape tactics, and physical
          techniques from an experienced trainer.
        </p>
      </div>
      <div className="w-[1100px] max-w-[1100px] mt-[50px] flex items-center justify-center flex-col">
        <h1
          id="problems-section"
          className=" uppercase mb-[40px] text-[40px] font-semibold text-[#171443]"
        >
          Project Volunteer Mindset Development
        </h1>
        <p className="text-center text-[#171443] text-[16px]">
          On June 18, 2022, a dynamic event focused on developing a volunteer
          mindset was held at Chattagram Eco Resort, combining both online and
          offline engagement methods to reach a wide audience. With a total of
          12,000 participants and beneficiaries, the event aimed to foster
          leadership skills, promote volunteerism, and raise awareness about
          drug addiction and prevention. The diverse format allowed for a rich
          exchange of ideas and strategies, bridging the gap between virtual and
          physical interactions. Participants engaged in workshops and
          discussions designed to enhance their social responsibility and
          commitment to community service. By addressing crucial issues like
          drug addiction through innovative approaches, the event sought to
          inspire a new generation of leaders and volunteers dedicated to making
          a positive impact on society. The successful blend of online and
          offline formats ensured broad accessibility and significant outreach,
          making it a landmark occasion for both personal and communal growth.
        </p>
      </div>
      <div className="w-[1100px] max-w-[1100px] mt-[50px] flex items-center justify-center flex-col">
        <h1
          id="problems-section"
          className=" uppercase mb-[40px] text-[40px] font-semibold text-[#171443]"
        >
          Project Shopnow Aki 1.0
        </h1>
        <p className="text-center text-[#171443] text-[16px]">
          "Project Shopnow Aki 1.0" was a transformative initiative held in
          Saidpur, Thakurgaon, aimed at supporting students facing educational
          challenges exacerbated by the COVID-19 pandemic. Conducted in
          collaboration with 10 primary schools in the area, the event targeted
          financially disadvantaged students who were at risk of discontinuing
          their education due to the hardships imposed by the pandemic. The
          primary objective was to reignite their passion for learning and
          encourage them to persevere in their educational journey. Sponsored by
          Bloop Ice-cream, this event exemplified Dreamers Bangladesh's
          commitment to empowering youth and fostering a culture of resilience
          and academic excellence within communities impacted by adversity.
        </p>
      </div>
    </div>
  );
};

export default ActivityHistory;
