// import React from "react";
// import { NavLink } from "react-router-dom";
// import logo from "../images/logo.jpeg";
// import logo2 from "../images/logo_2.png";
// import Menu from "./Menu";

// const Navbar = () => {
//   return (
//     <div className="h-[50px] md:h-[100px] border-b-[#7083E1] border-b-[1px] flex justify-evenly items-center text-[#171443] sticky top-0 z-30 bg-white uppercase">
//       <div className="flex">
//         <div className="object-cover">
//           <img src={logo2} alt="" height={100} width={100} />
//         </div>
//         <div className="text-base md:font-semibold uppercase flex items-center justify-center">
//           <NavLink className="" to="/">
//             <div className="flex flex-col">
//               <span className="text-[20px]">Dreamers</span> Bangladesh
//             </div>
//           </NavLink>
//         </div>
//       </div>
//       <div className="hidden md:flex md:items-center md:justify-center gap-4">
//         <NavLink className=" hover:text-[#7083E1]" to="/our-mission">
//           Our Mission
//         </NavLink>
//         <NavLink className="hover:text-[#7083E1]" to="/activity-history">
//           Activity History
//         </NavLink>
//         <NavLink className="hover:text-[#7083E1]" to="/projects">
//           Projects
//         </NavLink>
//         <NavLink to="/about-us" className="hover:text-[#7083E1]">
//           About
//         </NavLink>
//         <NavLink to="/get-involved" className="hover:text-[#7083E1]">
//           Get Involved
//         </NavLink>
//         {/* <NavLink to="/advisor-panel" className="hover:text-[#7083E1]">
//           Advisor panel
//         </NavLink> */}
//         <NavLink
//           to="/donate"
//           className="px-[10px] py-[8px] rounded-[2px] bg-[#27a00e] hover:bg-[#70d580] text-white text-center"
//         >
//           Donate
//         </NavLink>
//         <div className="flex items-center justify-center text-[12px] gap-2">
//           {/* <NavLink to="/about-us" className="hover:text-[#7083E1]">
//             About Us
//           </NavLink> */}
//           {/* <div className="h-[10px] w-[1px] bg-[#985a3b]"></div> */}
//           <NavLink to="/contact" className="hover:text-[#7083E1]">
//             Contact
//           </NavLink>
//           <div className="h-[10px] w-[1px] bg-[#985a3b]"></div>
//           <NavLink to="/blog" className="hover:text-[#7083E1]">
//             Blog
//           </NavLink>
//           <div className="h-[10px] w-[1px] bg-[#985a3b]"></div>
//           <NavLink to="/career" className="hover:text-[#7083E1]">
//             Career
//           </NavLink>
//         </div>
//       </div>
//       <div className="md:hidden">{<Menu />}</div>
//     </div>
//   );
// };

// export default Navbar;

import React from "react";
import { NavLink } from "react-router-dom";
import logo2 from "../images/logo_2.png";
import Menu from "./Menu";

const Navbar = () => {
  return (
    <div className="h-[60px] md:h-[100px] border-b-[#7083E1] border-b-[1px] flex justify-between items-center text-[#171443] sticky top-0 z-30 bg-white px-4 md:px-10 uppercase">
      <div className="flex items-center">
        {/* Logo */}
        <img
          src={logo2}
          alt="Logo"
          className="h-[50px] md:h-[80px] object-cover"
        />

        {/* Brand Name */}
        <NavLink
          to="/"
          className="ml-4 text-base md:font-semibold flex items-center"
        >
          <div className="flex flex-col">
            <span className="text-lg md:text-[24px]">Dreamers</span> Bangladesh
          </div>
        </NavLink>
      </div>

      {/* Desktop Navigation */}
      <div className="hidden md:flex items-center justify-center gap-6">
        <NavLink className="hover:text-[#7083E1]" to="/our-mission">
          Our Mission
        </NavLink>
        <NavLink className="hover:text-[#7083E1]" to="/activity-history">
          Activity History
        </NavLink>
        <NavLink className="hover:text-[#7083E1]" to="/projects">
          Projects
        </NavLink>
        <NavLink className="hover:text-[#7083E1]" to="/about-us">
          About
        </NavLink>
        <NavLink className="hover:text-[#7083E1]" to="/get-involved">
          Get Involved
        </NavLink>
        <NavLink
          to="/donate"
          className="px-4 py-2 bg-[#27a00e] hover:bg-[#70d580] text-white rounded-lg"
        >
          Donate
        </NavLink>

        {/* Extra links */}
        <div className="flex items-center justify-center gap-4 text-sm">
          <NavLink className="hover:text-[#7083E1]" to="/contact">
            Contact
          </NavLink>
          <NavLink className="hover:text-[#7083E1]" to="/blog">
            Blog
          </NavLink>
          <NavLink className="hover:text-[#7083E1]" to="/career">
            Career
          </NavLink>
        </div>
      </div>

      {/* Mobile Menu (Hamburger Icon) */}
      <div className="md:hidden">
        <Menu />
      </div>
    </div>
  );
};

export default Navbar;
