// import React, { useState } from "react";
// import { NavLink } from "react-router-dom";
// import openn from "../icons/open.png";
// import close from "../icons/close.png";

// const Menu = () => {
//   const [open, setOpen] = useState(false);
//   // const user = localStorage.getItem("token");
//   // const handleLogout = () => {
//   //   localStorage.removeItem("token");
//   //   // window.location.reload();
//   //   window.location = "/";
//   //   // navigate("/admin");
//   // };
//   // const NavLinks = [
//   //   { id: 1, title: "Homepage", url: "/" },
//   //   { id: 2, title: "Gallery", url: "/gallery" },
//   //   { id: 3, title: "Contact", url: "/contact" },
//   //   { id: 4, title: "Admin", url: "/admin" },
//   //   { id: 5, title: "Upload", url: "/upload" },
//   //   { id: 5, title: "Create Admin", url: "/signup" },
//   //   { id: 6, title: "Logout", url: "/" },
//   // ];

//   const NavLinks = [
//     { id: 1, title: "Homepage", url: "/" },
//     { id: 2, title: "Our Mission", url: "/our-mission" },
//     { id: 3, title: "Activity History", url: "/activity-history" },
//     { id: 2, title: "Projects", url: "/projects" },
//     { id: 3, title: "About", url: "/about" },
//     { id: 2, title: "Get Involved", url: "/get-involved" },
//     { id: 3, title: "Donate", url: "/donate" },
//     { id: 2, title: "Contact", url: "/contact" },
//     { id: 3, title: "Blog", url: "/blog" },
//     { id: 2, title: "Career", url: "/career" },
//   ];

//   // if (!user) {
//   //   NavLinks.push({ id: 4, title: "Admin", url: "/admin" });
//   // }

//   // if (user) {
//   //   NavLinks.push(
//   //     { id: 5, title: "Upload", url: "/upload" },
//   //     { id: 5, title: "Images", url: "/images" },
//   //     { id: 6, title: "Messages", url: "/messages" },
//   //     { id: 7, title: "Create Admin", url: "/signup" },
//   //     { id: 8, title: "Logout", url: "/", onClick: handleLogout }
//   //   );
//   // }

//   return (
//     <div>
//       {!open ? (
//         <img
//           src={openn}
//           alt=""
//           width={20}
//           height={20}
//           onClick={() => setOpen(true)}
//         />
//       ) : (
//         <img
//           src={close}
//           alt=""
//           width={20}
//           height={20}
//           onClick={() => setOpen(false)}
//         />
//       )}
//       {open && (
//         <div className="bg-white text-[#171443] absolute left-0 top-[100%] h-[calc(100vh-6rem)] w-full flex flex-col gap-4 items-center justify-center text-2xl z-30 overflow-y-scroll uppercase">
//           {NavLinks.map((item) => (
//             <NavLink
//               key={item.id}
//               to={item.url}
//               onClick={() => {
//                 setOpen(false);
//                 if (item.onClick) {
//                   item.onClick();
//                 }
//               }}
//             >
//               {item.title}
//             </NavLink>
//           ))}
//           {/* {user && (
//             <NavLink to="/upload" onClick={setOpen(false)}>
//               Upload
//             </NavLink>
//           )}
//           {user && (
//             <NavLink
//               onClick={() => {
//                 setOpen(false);
//                 handleLogout();
//               }}
//             >
//               Logout
//             </NavLink>
//           )} */}
//         </div>
//       )}
//     </div>
//   );
// };

// export default Menu;

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import openn from "../icons/open.png";
import close from "../icons/close.png";

const Menu = () => {
  const [open, setOpen] = useState(false);

  const NavLinks = [
    { id: 1, title: "Homepage", url: "/" },
    { id: 2, title: "Our Mission", url: "/our-mission" },
    { id: 3, title: "Activity History", url: "/activity-history" },
    { id: 4, title: "Projects", url: "/projects" },
    { id: 5, title: "About", url: "/about-us" },
    { id: 6, title: "Get Involved", url: "/get-involved" },
    { id: 7, title: "Donate", url: "/donate" },
    { id: 8, title: "Contact", url: "/contact" },
    { id: 9, title: "Blog", url: "/blog" },
    { id: 10, title: "Career", url: "/career" },
  ];

  return (
    <div>
      {!open ? (
        <img
          src={openn}
          alt="Open Menu"
          width={24}
          height={24}
          onClick={() => setOpen(true)}
        />
      ) : (
        <img
          src={close}
          alt="Close Menu"
          width={24}
          height={24}
          onClick={() => setOpen(false)}
        />
      )}

      {/* Mobile Menu Dropdown */}
      {open && (
        <div className="bg-white text-[#171443] absolute left-0 top-[60px] md:top-[100px] h-[calc(100vh-10rem)] md:h-[calc(100vh-100px)] w-full flex flex-col gap-6 items-center justify-center text-lg z-30 overflow-y-auto uppercase">
          {NavLinks.map((item) => (
            <NavLink
              key={item.id}
              to={item.url}
              className="hover:text-[#7083E1]"
              onClick={() => setOpen(false)}
            >
              {item.title}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
};

export default Menu;
