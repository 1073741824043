// import React from "react";

// import p1 from "../images/p1.jpeg";
// import p2 from "../images/p2.jpeg";
// import p3 from "../images/p3.jpeg";
// import p4 from "../images/p4.jpeg";
// import p5 from "../images/p5.jpeg";

// const Partners = () => {
//   return (
//     <div className="w-full flex items-center justify-center">
//       <div className="grid grid-cols-2 md:grid-cols-3 md:flex gap-6">
//         {/* Image 1 */}
//         <div className="w-[150px] h-[150px] flex items-center justify-center border rounded-md overflow-hidden">
//           <img src={p1} alt="Partner 1" className="w-full h-full object-fit" />
//         </div>
//         {/* Image 2 */}
//         <div className="w-[150px] h-[150px] flex items-center justify-center border rounded-md overflow-hidden">
//           <img
//             src={p2}
//             alt="Partner 2"
//             // width={100}
//             className="w-full h-full object-fit"
//           />
//         </div>
//         {/* Image 3 */}
//         <div className="w-[150px] h-[150px] flex items-center justify-center border rounded-md overflow-hidden">
//           <img src={p3} alt="Partner 3" className="w-full h-full object-fit" />
//         </div>
//         {/* Image 4 */}
//         <div className="w-[150px] h-[150px] flex items-center justify-center border rounded-md overflow-hidden">
//           <img src={p4} alt="Partner 4" className="w-full h-full object-fit" />
//         </div>
//         {/* Image 5 */}
//         <div className="w-[150px] h-[150px] flex items-center justify-center border rounded-md overflow-hidden">
//           <img src={p5} alt="Partner 5" className="w-full h-full object-fit" />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Partners;

import React from "react";

import p1 from "../images/p1.jpeg";
import p2 from "../images/p2.jpeg";
import p3 from "../images/p3.jpeg";
import p4 from "../images/p4.jpeg";
import p5 from "../images/p5.jpeg";

const Partners = () => {
  return (
    <div className="w-full flex items-center justify-center py-8">
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
        {/* Image 1 */}
        <div className="w-[150px] h-[150px] flex items-center justify-center border rounded-md overflow-hidden">
          <img
            src={p1}
            alt="Partner 1"
            className="w-full h-full object-cover"
          />
        </div>
        {/* Image 2 */}
        <div className="w-[150px] h-[150px] flex items-center justify-center border rounded-md overflow-hidden">
          <img
            src={p2}
            alt="Partner 2"
            className="w-full h-full object-cover"
          />
        </div>
        {/* Image 3 */}
        <div className="w-[150px] h-[150px] flex items-center justify-center border rounded-md overflow-hidden">
          <img
            src={p3}
            alt="Partner 3"
            className="w-full h-full object-cover"
          />
        </div>
        {/* Image 4 */}
        <div className="w-[150px] h-[150px] flex items-center justify-center border rounded-md overflow-hidden">
          <img src={p4} alt="Partner 4" className="w-full h-full object-fit" />
        </div>
        {/* Image 5 */}
        <div className="w-[150px] h-[150px] flex items-center justify-center border rounded-md overflow-hidden">
          <img
            src={p5}
            alt="Partner 5"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default Partners;
