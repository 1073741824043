// import React from "react";

// const Modal = ({ item, onClose }) => {
//   return (
//     <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
//       <div className="bg-white rounded-lg p-6 max-w-lg mx-auto">
//         <h4 className="font-semibold uppercase text-center text-xl mb-4">
//           {item.title}
//         </h4>
//         <img
//           src={item.img}
//           alt={item.title}
//           className="w-full h-[300px] object-cover rounded-md mb-4"
//         />
//         <p className="text-sm md:text-base mb-4">{item.desc}</p>
//         <button className="mt-4 text-red-500 hover:underline" onClick={onClose}>
//           Close
//         </button>
//       </div>
//     </div>
//   );
// };

// export default Modal;
import React from "react";

const Modal = ({ item, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 ">
      <div className="bg-white rounded-lg p-6 max-w-lg mx-auto transform transition-transform duration-[1000ms] ease-out scale-100 animate-fadeIn">
        <h4 className="font-semibold uppercase text-center text-xl mb-4">
          {item.title}
        </h4>
        <img
          src={item.img}
          alt={item.title}
          className="w-full h-[300px] object-fit rounded-md mb-4"
        />
        <p className="text-sm md:text-base mb-4">{item.desc}</p>
        <button className="mt-4 text-red-500 hover:underline" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal;
