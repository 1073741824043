import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import pnf from "../../images/page_not_found.jpg";

const PageNotFound = () => {
  const navigate = useNavigate(); // Initialize navigate function

  const handleGoHome = () => {
    navigate("/"); // Navigate to the home page
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 relative">
      <div className="w-full p-4 flex items-center justify-center">
        <img
          src={pnf}
          alt="Page Not Found"
          className="max-w-md max-h-[500px] object-contain"
        />
        <button
          onClick={handleGoHome}
          className="absolute top-[65%] left-1/2 transform -translate-x-1/2 bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600"
        >
          Go Back Home
        </button>
      </div>
    </div>
  );
};

export default PageNotFound;
