// import React, { useEffect, useRef, useState } from "react";
// import CountUp from "react-countup";

// const Impact = () => {
//   const [inView, setInView] = useState(false);
//   const ref = useRef(null);

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         if (entry.isIntersecting) {
//           setInView(true);
//           observer.disconnect(); // Stop observing after the first entry
//         }
//       },
//       { threshold: 0.1 } // Trigger when 10% of the element is in view
//     );

//     if (ref.current) {
//       observer.observe(ref.current);
//     }

//     return () => {
//       if (ref.current) {
//         observer.unobserve(ref.current);
//       }
//     };
//   }, []);

//   return (
//     <div ref={ref} className="flex gap-4 uppercase">
//       <div className="flex flex-col items-center justify-center w-[350px] h-[200px] border-r-[3px] border-blue-800 hover:bg-[#eaedee]">
//         <div className="text-[30px] font-semibold text-[#2596be]">
//           Zero Hunger
//         </div>
//         <div className="text-[50px] font-semibold text-green-500">
//           {inView && (
//             <CountUp delay={0.4} start={0} end={14500} duration={1.5} />
//           )}
//         </div>
//       </div>
//       <div className="flex flex-col items-center justify-center w-[350px] h-[200px] border-r-[3px] border-blue-800 hover:bg-[#eaedee]">
//         <div className="text-[30px] font-semibold text-[#2596be]">
//           Quality Education
//         </div>
//         <div className="text-[50px] font-semibold text-orange-500">
//           {inView && (
//             <CountUp delay={0.4} start={0} end={19587} duration={1.4} />
//           )}
//         </div>
//       </div>
//       <div className="flex flex-col items-center justify-center w-[350px] h-[200px] hover:bg-[#eaedee]">
//         <div className="text-[30px] font-semibold text-[#2596be]">
//           No Poverty
//         </div>
//         <div className="text-[50px] font-semibold text-blue-700">
//           {inView && (
//             <CountUp delay={0.4} start={0} end={3450} duration={1.8} />
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Impact;

// import React, { useEffect, useRef, useState } from "react";
// import CountUp from "react-countup";

// const Impact = () => {
//   const [inView, setInView] = useState(false);
//   const ref = useRef(null);

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         if (entry.isIntersecting) {
//           setInView(true);
//           observer.disconnect(); // Stop observing after the first entry
//         }
//       },
//       { threshold: 0.1 } // Trigger when 10% of the element is in view
//     );

//     if (ref.current) {
//       observer.observe(ref.current);
//     }

//     return () => {
//       if (ref.current) {
//         observer.unobserve(ref.current);
//       }
//     };
//   }, []);

//   return (
//     <div
//       ref={ref}
//       className="flex flex-col md:flex-row gap-4 uppercase px-4 md:px-0"
//     >
//       <div className="flex flex-col items-center justify-center w-full md:w-[30%] p-4 h-[200px] border-r-[3px] border-blue-800 hover:bg-[#eaedee] transition-colors">
//         <div className="text-[24px] md:text-[30px] font-semibold text-[#2596be]">
//           Zero Hunger
//         </div>
//         <div className="text-[40px] md:text-[50px] font-semibold text-green-500">
//           {inView && (
//             <CountUp delay={0.4} start={0} end={14500} duration={1.5} />
//           )}
//         </div>
//       </div>

//       <div className="flex flex-col items-center justify-center w-full md:w-[30%] p-4 h-[200px] border-r-[3px] border-blue-800 hover:bg-[#eaedee] transition-colors">
//         <div className="text-[24px] md:text-[30px] font-semibold text-[#2596be]">
//           Quality Education
//         </div>
//         <div className="text-[40px] md:text-[50px] font-semibold text-orange-500">
//           {inView && (
//             <CountUp delay={0.4} start={0} end={19587} duration={1.4} />
//           )}
//         </div>
//       </div>

//       <div className="flex flex-col items-center justify-center w-full md:w-[30%] p-4 h-[200px] hover:bg-[#eaedee] transition-colors">
//         <div className="text-[24px] md:text-[30px] font-semibold text-[#2596be]">
//           No Poverty
//         </div>
//         <div className="text-[40px] md:text-[50px] font-semibold text-blue-700">
//           {inView && (
//             <CountUp delay={0.4} start={0} end={3450} duration={1.8} />
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Impact;

// import React, { useEffect, useRef, useState } from "react";
// import CountUp from "react-countup";

// const Impact = () => {
//   const [inView, setInView] = useState(false);
//   const ref = useRef(null);

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         if (entry.isIntersecting) {
//           setInView(true);
//           observer.disconnect(); // Stop observing after the first entry
//         }
//       },
//       { threshold: 0.1 } // Trigger when 10% of the element is in view
//     );

//     if (ref.current) {
//       observer.observe(ref.current);
//     }

//     return () => {
//       if (ref.current) {
//         observer.unobserve(ref.current);
//       }
//     };
//   }, []);

//   return (
//     <div
//       ref={ref}
//       className="flex flex-col md:flex-row gap-4 uppercase px-4 md:px-0"
//     >
//       <div className="flex flex-col items-center justify-center w-full md:w-[30%] p-6 h-[250px] border-r-[3px] border-blue-800 hover:bg-[#eaedee] transition-colors">
//         <div className="text-[28px] md:text-[36px] font-semibold text-[#2596be]">
//           Zero Hunger
//         </div>
//         <div className="text-[48px] md:text-[60px] font-semibold text-green-500">
//           {inView && (
//             <CountUp delay={0.4} start={0} end={14500} duration={1.5} />
//           )}
//         </div>
//       </div>

//       <div className="flex flex-col items-center justify-center w-full md:w-[30%] p-6 h-[250px] border-r-[3px] border-blue-800 hover:bg-[#eaedee] transition-colors">
//         <div className="text-[28px] md:text-[36px] font-semibold text-[#2596be]">
//           Quality Education
//         </div>
//         <div className="text-[48px] md:text-[60px] font-semibold text-orange-500">
//           {inView && (
//             <CountUp delay={0.4} start={0} end={19587} duration={1.4} />
//           )}
//         </div>
//       </div>

//       <div className="flex flex-col items-center justify-center w-full md:w-[30%] p-6 h-[250px] hover:bg-[#eaedee] transition-colors">
//         <div className="text-[28px] md:text-[36px] font-semibold text-[#2596be]">
//           No Poverty
//         </div>
//         <div className="text-[48px] md:text-[60px] font-semibold text-blue-700">
//           {inView && (
//             <CountUp delay={0.4} start={0} end={3450} duration={1.8} />
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Impact;

// import React, { useEffect, useRef, useState } from "react";
// import CountUp from "react-countup";

// const Impact = () => {
//   const [inView, setInView] = useState(false);
//   const ref = useRef(null);

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         if (entry.isIntersecting) {
//           setInView(true);
//           observer.disconnect(); // Stop observing after the first entry
//         }
//       },
//       { threshold: 0.1 } // Trigger when 10% of the element is in view
//     );

//     if (ref.current) {
//       observer.observe(ref.current);
//     }

//     return () => {
//       if (ref.current) {
//         observer.unobserve(ref.current);
//       }
//     };
//   }, []);

//   return (
//     <div
//       ref={ref}
//       className="flex flex-col md:flex-row gap-4 uppercase px-4 md:px-0 md:ml-20"
//     >
//       <div className="flex flex-col items-center justify-center w-full md:w-[30%] p-6 h-[250px] border-r-[3px] border-blue-800 hover:bg-[#eaedee] transition-colors">
//         <div className="text-[28px] md:text-[36px] font-semibold text-[#2596be]">
//           Zero Hunger
//         </div>
//         <div className="text-[48px] md:text-[60px] font-semibold text-green-500">
//           {inView && (
//             <CountUp delay={0.4} start={0} end={14500} duration={1.5} />
//           )}
//         </div>
//       </div>

//       <div className="flex flex-col items-center justify-center w-full md:w-[30%] p-6 h-[250px] border-r-[3px] border-blue-800 hover:bg-[#eaedee] transition-colors">
//         <div className="text-[28px] md:text-[36px] font-semibold text-[#2596be]">
//           Quality Education
//         </div>
//         <div className="text-[48px] md:text-[60px] font-semibold text-orange-500">
//           {inView && (
//             <CountUp delay={0.4} start={0} end={19587} duration={1.4} />
//           )}
//         </div>
//       </div>

//       <div className="flex flex-col items-center justify-center w-full md:w-[30%] p-6 h-[250px] border-r-[3px] border-blue-800 hover:bg-[#eaedee] transition-colors md:border-r-0">
//         <div className="text-[28px] md:text-[36px] font-semibold text-[#2596be]">
//           No Poverty
//         </div>
//         <div className="text-[48px] md:text-[60px] font-semibold text-blue-700">
//           {inView && (
//             <CountUp delay={0.4} start={0} end={3450} duration={1.8} />
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Impact;
import React, { useEffect, useRef, useState } from "react";
import CountUp from "react-countup";

const Impact = () => {
  const [inView, setInView] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true);
          observer.disconnect(); // Stop observing after the first entry
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the element is in view
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div
      ref={ref}
      className="flex flex-col md:flex-row gap-4 uppercase px-4 md:px-0 md:ml-20"
    >
      <div className="flex flex-col items-center justify-center w-full md:w-[40%] p-6 h-[250px] border-r-[3px] border-blue-800 hover:bg-[#eaedee] transition-colors">
        <div className="text-[20px] md:text-[30px] font-semibold text-[#2596be]">
          Zero Hunger
        </div>
        <div className="text-[32px] md:text-[50px] font-semibold text-green-500">
          {inView && (
            <CountUp delay={0.4} start={0} end={14500} duration={1.5} />
          )}
        </div>
      </div>

      <div className="flex flex-col items-center justify-center w-full md:w-[40%] p-6 h-[250px] border-r-[3px] border-blue-800 hover:bg-[#eaedee] transition-colors">
        <div className="text-[20px] md:text-[30px] font-semibold text-[#2596be]">
          Quality Education
        </div>
        <div className="text-[32px] md:text-[50px] font-semibold text-orange-500">
          {inView && (
            <CountUp delay={0.4} start={0} end={19587} duration={1.4} />
          )}
        </div>
      </div>

      <div className="flex flex-col items-center justify-center w-full md:w-[40%] p-6 h-[250px] border-r-[3px] border-blue-800 hover:bg-[#eaedee] transition-colors md:border-r-0">
        <div className="text-[20px] md:text-[30px] font-semibold text-[#2596be]">
          No Poverty
        </div>
        <div className="text-[32px] md:text-[50px] font-semibold text-blue-700">
          {inView && (
            <CountUp delay={0.4} start={0} end={3450} duration={1.8} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Impact;
