import React from "react";
import uc from "../../images/underconstruction.png";
const Resources = () => {
  return (
    <div className="">
      <div className="w-full h-full flex items-center justify-center">
        <img src={uc} alt="" className="object-cover" />
      </div>
    </div>
  );
};

export default Resources;
